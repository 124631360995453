import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Alert } from "../../../../components/design-system/Alert";
import { PublicPageLayout } from "../../../../components/PublicPageLayout";
import { APP_ROUTE } from "../../../../routes/constants";

function getOrgNumberFromPendingApprovalInStorage() {
  return JSON.parse(localStorage.getItem("pendingApprovalPolicyChange") ?? "{}")
    ?.orgNumber;
}

function getOrgNumberFromPendingApprovalReviewInStorage() {
  return JSON.parse(localStorage.getItem("pendingApprovalPolicyReview") ?? "{}")
    ?.orgNumber;
}
const TruidCompleteSignPage = () => {
  const [searchParams] = useSearchParams();
  const i18n = useTranslation();

  const navigate = useNavigate();

  const code = searchParams.get("code");
  const state = searchParams.get("state");
  const error = searchParams.get("error");
  const response = code ?? error;
  const hasWrongParams = !state || !response;

  useEffect(() => {
    let orgNumber = getOrgNumberFromPendingApprovalInStorage();
    if (orgNumber && code && state) {
      navigate(
        `${APP_ROUTE.COMPANIES}/${orgNumber}/settings/policy/change?truidCode=${code}&truidState=${state}`
      );
    }

    orgNumber = getOrgNumberFromPendingApprovalReviewInStorage();
    if (orgNumber && code && state) {
      navigate(
        `${APP_ROUTE.COMPANIES}/${orgNumber}/settings/policy/preview-proposal?truidCode=${code}&truidState=${state}`
      );
    }
  }, [code, state, navigate]);

  if (hasWrongParams) {
    console.error(
      "Wrong search params in Truid response!",
      window.location.href
    );
    navigate(APP_ROUTE.HOME);
    return <div />;
  }

  return (
    <PublicPageLayout>
      <div className="tw-w-full tw-items-center">
        {error && <Alert type="error">{i18n.t("auth.Truid.signFailed")}</Alert>}
      </div>
    </PublicPageLayout>
  );
};

export { TruidCompleteSignPage };
