import { useQuery } from "@tanstack/react-query";

import { useBlockchainClient } from "../../context/blockchain";
import { useSession } from "../../context/session";
import {
  type CompanyInvolvement,
  CompanyInvolvementsSchema,
} from "../../types/models/company";
import type {
  ApprovalInfoResponse,
  Ledger,
  LedgerVersion,
  Shareblock,
  Shareholder,
  ShareType,
  ShareTypeClause,
} from "../../types/models/shares";
import { ApprovalInfoResponseSchema } from "../../types/models/shares";
import * as monitoring from "../../utils/monitoring";
import { getActiveBlocks } from "../../utils/shares";
import type { IRequestError } from "..";
import type { ApprovalRule } from "../rest/approval-rule-policy";
import { unmaskValues } from "../rest/company";
import type { UseQueryOptions } from "../types";
import { BlockchainClient } from "./client";
import { extractRefIdFromUnmaskedValue } from "./events";

const getInvolvements = async (
  blockchainClient: BlockchainClient,
  pubKey: string
): Promise<CompanyInvolvement[]> => {
  const response = await blockchainClient.query<CompanyInvolvement[]>(
    "ledger_access.get_company_involvements",
    { pubkey: pubKey }
  );

  const result = CompanyInvolvementsSchema.safeParse(response);

  if (!result.success) {
    monitoring.captureException(
      TypeError(
        "Error parsing blockchain response using CompanyInvolvementsSchema"
      ),
      { contexts: { result } }
    );

    return response;
  }

  return result.data;
};

const getShareblocks = (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  ledgerVersion: LedgerVersion | "" = ""
): Promise<Shareblock[]> =>
  blockchainClient.query<Shareblock[]>("ledger.get_share_blocks", {
    org_number: orgNumber,
    date: ledgerVersion,
  });

const getApprovalInfo = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  date: string
) => {
  const response = await blockchainClient.query<ApprovalInfoResponse | null>(
    "ledger.get_approval_info",
    {
      org_number: orgNumber,
      date,
    }
  );

  ApprovalInfoResponseSchema.parse(response);

  return response;
};

const getActiveApprovalRule = (
  blockchainClient: BlockchainClient,
  orgNumber: string
) =>
  blockchainClient.query<ApprovalRule>("ledger.get_active_approval_rule", {
    org_number: orgNumber,
  });

type ShareTypeResponse = Omit<ShareType, "condition"> & {
  condition: {
    consent: 0 | 1;
    preemption: 0 | 1;
    offerOfFirstRefusal: 0 | 1;
    conversion: 0 | 1;
    redemption: 0 | 1;
  };
};

const getShareTypes = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  date: string
) => {
  const data = await blockchainClient.query<ShareTypeResponse[]>(
    "ledger.get_share_types",
    {
      org_number: orgNumber,
      date,
    }
  );

  return data.map(({ condition, isUsed, ...rest }) => ({
    ...rest,
    condition: Object.fromEntries(
      Object.keys(condition).map((curr) => [
        curr,
        !!condition[curr as ShareTypeClause],
      ])
    ),
    isUsed: Boolean(isUsed),
  }));
};

type LedgerMetaResponse = {
  capital: string;
  shares: { lastNumber: number; total: number };
};

const getLedger = async (
  blockchainClient: BlockchainClient,
  orgNumber: string,
  date: string
) => {
  const data = await blockchainClient.query<LedgerMetaResponse>(
    "ledger.get_ledger_meta",
    {
      org_number: orgNumber,
      date,
    }
  );

  return {
    ...data,
    capital: parseFloat(data.capital),
  };
};

const useLedgerQuery = (
  orgNumber: string,
  ledgerVersion: LedgerVersion | "" | undefined,
  options?: UseQueryOptions<unknown, IRequestError, Ledger, string[]>
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery(
    ["ledger", orgNumber, ledgerVersion || ""],
    () => getLedger(blockchainClient, orgNumber, ledgerVersion || ""),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && ledgerVersion !== undefined,
      ...options,
    }
  );
};

const useCompaniesQuery = () => {
  const blockchainClient = useBlockchainClient();
  const { keyPair } = useSession();
  const pubKey = keyPair?.pubKey.toString("hex") || "";

  return useQuery(["companyInvolvements"], () =>
    getInvolvements(blockchainClient, pubKey)
  );
};

const useShareblocksQuery = (
  orgNumber: string | undefined,
  ledgerVersion: LedgerVersion | "" | undefined,
  options: UseQueryOptions<unknown, IRequestError, Shareblock[], string[]> = {}
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery<unknown, IRequestError, Shareblock[], string[]>(
    ["companyShareblocks", orgNumber || "", ledgerVersion || ""],
    async (): Promise<Shareblock[]> => {
      const shareblocks = await getShareblocks(
        blockchainClient,
        orgNumber || "",
        ledgerVersion
      );

      /**
       * !IMPORTANT! We're patching the response here, because we need to have
       * the unmasked refIds later on. We're adding a property to each
       * EventEntity called "unmaskedRefId" which contains the unmasked refId
       * found in the entityMap, but more importantly, this is not added to the
       * type-definition, because we don't want to pollute it since we'll remove
       * it at a later point anyways.
       */
      const maskedRefIds = shareblocks.map((block) => block.holder.refId);
      const uniqueMaskedIds = [...new Set(maskedRefIds)];
      const unmasked = await unmaskValues(
        orgNumber || "",
        uniqueMaskedIds,
        true
      );

      const patchedShareblocks: Shareblock[] = shareblocks.map((block) => ({
        ...block,
        holder: {
          ...block.holder,
          unmaskedRefId: extractRefIdFromUnmaskedValue(
            unmasked[block.holder.refId]
          ),
        },
      }));

      return patchedShareblocks;
    },
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && ledgerVersion !== undefined,
      ...options,
    }
  );
};

const useApprovalInfoQuery = (
  orgNumber: string,
  date: string | undefined,
  options: UseQueryOptions<
    ApprovalInfoResponse | null,
    IRequestError,
    ApprovalInfoResponse | null,
    string[]
  > = {}
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery<
    ApprovalInfoResponse | null,
    IRequestError,
    ApprovalInfoResponse | null,
    string[]
  >(
    ["approvalInfo", orgNumber, date || ""],
    () => getApprovalInfo(blockchainClient, orgNumber, date || ""),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && date !== undefined,
      ...options,
    }
  );
};

const useActiveApprovalRuleQuery = (
  orgNumber: string,
  options?: UseQueryOptions<ApprovalRule, IRequestError, ApprovalRule, string[]>
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery<ApprovalRule, IRequestError, ApprovalRule, string[]>(
    ["approvalActive", orgNumber],
    () => getActiveApprovalRule(blockchainClient, orgNumber),
    {
      enabled: options?.enabled !== undefined ? options.enabled : !!orgNumber,
      ...options,
    }
  );
};

const useShareTypesQuery = (
  orgNumber: string | undefined,
  date: string | undefined,
  options?: UseQueryOptions<unknown, IRequestError, ShareType[], string[]>
) => {
  const blockchainClient = useBlockchainClient();

  return useQuery(
    ["shareTypes", orgNumber || "", date || ""],
    () => getShareTypes(blockchainClient, orgNumber || "", date || ""),
    {
      enabled:
        options?.enabled !== undefined
          ? options.enabled
          : !!orgNumber && date !== undefined,
      ...options,
    }
  );
};

const useShareholdersQuery = (
  orgNumber: string,
  ledgerVersion?: LedgerVersion
) => {
  const shareBlocksQuery = useShareblocksQuery(orgNumber, ledgerVersion || "");
  const getShareholders = (blocks: Shareblock[]) => {
    const activeBlocks = getActiveBlocks(blocks);
    const map = activeBlocks.reduce<Record<string, Shareholder>>(
      (prev, curr) => {
        const holder = prev[curr.holder.id];
        const { holder: _, ...blockValue } = curr;

        return {
          ...prev,
          [curr.holder.id]: {
            ...curr.holder,
            blocks: [...(holder?.blocks ?? []), blockValue],
          },
        };
      },
      {}
    );
    return Object.values(map);
  };

  return {
    ...shareBlocksQuery,
    data: getShareholders(shareBlocksQuery.data || []),
  };
};

export {
  useActiveApprovalRuleQuery,
  useApprovalInfoQuery,
  useCompaniesQuery,
  useLedgerQuery,
  useShareblocksQuery,
  useShareholdersQuery,
  useShareTypesQuery,
};
