import { KeyPair } from "postchain-client";

export const setKeyPairInStorage = (
  key: "keyPair" | "pendingKeyPair",
  keypair: KeyPair
) => {
  window.localStorage.setItem(
    key,
    JSON.stringify({
      publicKey: keypair.pubKey.toString("hex"),
      privateKey: keypair.privKey.toString("hex"),
    })
  );
};
