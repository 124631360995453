import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

import { Button } from "../../../../components/design-system/Button";
import { PublicPageLayout } from "../../../../components/PublicPageLayout";
import { useSession } from "../../../../context/session";
import { APP_ROUTE } from "../../../../routes/constants";

const TruidCompleteSignInPage = () => {
  const [searchParams] = useSearchParams();
  const session = useSession();

  const [waitForResult, setWaitForResult] = useState(false);
  const navigate = useNavigate();
  const i18n = useTranslation();
  const isInIframe = window.self !== window.top && window.frameElement;
  useEffect(() => {
    if (!searchParams || waitForResult) {
      return;
    }

    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const error = searchParams.get("error");
    const response = code ?? error;
    const hasWrongParams = !state || !response;

    if (hasWrongParams) {
      console.error(
        "Wrong search params in Truid response!",
        window.location.href
      );
      return;
    }

    if (isInIframe) {
      window.top?.postMessage(
        {
          code,
          state,
          error,
          type: "TruidResponse",
        },
        window.location.origin
      );
      setWaitForResult(true);
    } else if (session.collectTruidAuthMutation?.isIdle && !waitForResult) {
      session.onCollectTruidAuth(response, state);
      setWaitForResult(true);
    }
  }, [searchParams, session, waitForResult, isInIframe]);

  useEffect(() => {
    if (session.user && !isInIframe) {
      navigate(APP_ROUTE.HOME);
    }
  }, [session.user, navigate, isInIframe]);

  if (!isInIframe && session?.collectTruidAuthMutation?.isError) {
    return (
      <PublicPageLayout>
        <div className="tw-flex tw-w-full tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
          {i18n.t("auth.Truid.loginFailed")}

          <Button
            onClick={() => {
              session.initTruidAuthMutation?.reset();
              session.collectTruidAuthMutation?.reset();
              navigate(APP_ROUTE.SIGN_IN);
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("auth.backToLogin")}
          </Button>
        </div>
      </PublicPageLayout>
    );
  }

  return null;
};

export { TruidCompleteSignInPage };
