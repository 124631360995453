import { FilePdf } from "@phosphor-icons/react";
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

import {
  useLedgerQuery,
  useShareblocksQuery,
  useShareholdersQuery,
  useShareTypesQuery,
} from "../../api/blockchain/company";
import { useParentEventsQuery } from "../../api/blockchain/events";
import { useOptionsProgramQuery } from "../../api/blockchain/options";
import { shareholdersDocumentDownload } from "../../api/rest/company";
import { useEntitiesQuery } from "../../api/rest/entities";
import { BottomBar } from "../../components/design-system/BottomBar";
import { Chip } from "../../components/design-system/Chip";
import { Description } from "../../components/design-system/Description";
import { EntityItem } from "../../components/design-system/EntityItem";
import {
  ExcelIcon,
  InfoIcon,
  SearchIcon,
  SortIcon,
} from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { Menu } from "../../components/design-system/Menu";
import { Table } from "../../components/design-system/Table";
import { Toggle } from "../../components/design-system/Toggle";
import { getEntityWithFallback } from "../../components/EventList/EventsTable/EventsTable.utils";
import { NoData } from "../../components/NoData";
import { PageWrapper } from "../../components/PageWrapper";
import { SelectionMenuItem } from "../../components/SelectionMenuItem";
import { SelectVersion } from "../../components/SelectVersion";
import { getTotalSharesByType } from "../../components/ShareBlocks/ShareBlocks.utils";
import { ShareHoldersRow } from "../../components/ShareHolders/ShareHoldersRow";
import { SharesPrint } from "../../components/SharesPrint";
import { getLocale } from "../../i18n";
import type { CompanyInformation } from "../../types/models/administration";
import type { CompanyInvolvement } from "../../types/models/company";
import type { Entity } from "../../types/models/entities";
import { OptionsProgram } from "../../types/models/options";
import type {
  Shareholder,
  ShareHolderWithMetricsAndEntity,
} from "../../types/models/shares";
import { getFormattedDate, isGreaterLedgerVersion } from "../../utils/date";
import { downloadBlob } from "../../utils/download";
import { makeAndDownloadExcel } from "../../utils/excel";
import { generateShareholderData } from "../../utils/excel-utils";
import { formatNumber, formatPercentage } from "../../utils/format";
import * as monitoring from "../../utils/monitoring";
import { calcSumWithinRange } from "../../utils/shares";
import { clsxm } from "../../utils/tailwind";
import {
  getDilutionTotals,
  getShareHoldersWithDilution,
  getTotalVotes,
  ShareHolderWithDilution,
} from "./ShareHolders.utils";
import type { LedgerVersionDetails } from "./useLedgerVersions";

type Props = {
  currentCompany: CompanyInvolvement | CompanyInformation;
  ledgerVersions: LedgerVersionDetails[];
  selectedVersion?: LedgerVersionDetails;
  setSelectedVersion: (version?: LedgerVersionDetails) => void;
};

const SortBySchema = z.enum([
  "name-asc",
  "name-desc",
  "shares-asc",
  "shares-desc",
  "votes-asc",
  "votes-desc",
  "sharesDiluted-desc",
  "sharesDiluted-asc",
  "votesDiluted-desc",
  "votesDiluted-asc",
]);

type SortBy = z.infer<typeof SortBySchema>;

export const CLASS_CELL_GAP = "tw-gap-6";

const sortHolders = (holders: ShareHolderWithDilution[], key: SortBy) => {
  const [sortBy, sortOrder] = key.split("-");
  const isAscending = sortOrder === "asc";
  let compareFunction = (
    a: ShareHolderWithDilution,
    b: ShareHolderWithDilution
  ) => (isAscending ? 1 : -1) * a.entity.name.localeCompare(b.entity.name);

  if (sortBy === "shares") {
    compareFunction = (a, b) =>
      isAscending ? a.nrOfShares - b.nrOfShares : b.nrOfShares - a.nrOfShares;
  } else if (sortBy === "votes") {
    compareFunction = (a, b) =>
      isAscending ? a.nrOfVotes - b.nrOfVotes : b.nrOfVotes - a.nrOfVotes;
  } else if (sortBy === "sharesDiluted") {
    compareFunction = (a, b) =>
      isAscending
        ? (a.dilutedShares || 0) - (b.dilutedShares || 0)
        : (b.dilutedShares || 0) - (a.dilutedShares || 0);
  } else if (sortBy === "votesDiluted") {
    compareFunction = (a, b) =>
      isAscending
        ? (a.dilutedVotes || 0) - (b.dilutedVotes || 0)
        : (b.dilutedVotes || 0) - (a.dilutedVotes || 0);
  }

  return holders.sort((a, b) => {
    if (!a || !b) {
      return 0;
    }
    return compareFunction(a, b);
  });
};

const shareHolderMatchSearchValue = (
  shareHolder: ShareHolderWithMetricsAndEntity,
  searchValue?: string
) => {
  if (!searchValue || searchValue.length < 1) {
    return true;
  }
  const { entity } = shareHolder;

  return (
    entity.name.toLowerCase().includes(searchValue.toLowerCase()) ||
    entity.refId.toLowerCase().includes(searchValue.toLowerCase()) ||
    shareHolder.blocks.some((block) =>
      block.type.toLowerCase().includes(searchValue.toLowerCase())
    )
  );
};

const getShareHolderMetrics = (
  holder: Shareholder,
  shareTypes: Record<string, number>
) => {
  const nrOfShares = holder.blocks.reduce(
    (prev, curr) => prev + calcSumWithinRange(curr),
    0
  );

  const nrOfVotes = holder.blocks.reduce(
    (result, shareType) =>
      result +
      calcSumWithinRange(shareType) * (shareTypes[shareType.type] ?? 0),
    0
  );

  const sharesByType: Record<string, number> = holder.blocks.reduce(
    (result, shareType) => ({
      ...result,
      [shareType.type]:
        calcSumWithinRange(shareType) + (result[shareType.type] ?? 0),
    }),
    {} as Record<string, number>
  );

  return { ...holder, nrOfShares, nrOfVotes, sharesByType };
};

const OptionsProgramFilter = ({
  optionsPrograms,
  selectedOptionsPrograms,
  setSelectedOptionsPrograms,
  className,
}: {
  optionsPrograms: OptionsProgram[];
  selectedOptionsPrograms: string[];
  setSelectedOptionsPrograms: Dispatch<SetStateAction<string[]>>;
  className?: string;
}) => (
  <div className={clsxm("tw-flex tw-flex-wrap tw-gap-2", className)}>
    {optionsPrograms.map((p) => {
      return (
        <Chip
          key={p.title}
          isActive={selectedOptionsPrograms.includes(p.id!)}
          onClick={() =>
            setSelectedOptionsPrograms((prevState) => {
              if (prevState.includes(p.id!)) {
                return prevState.filter((item) => item !== p.id);
              }
              return [...prevState, p.id!];
            })
          }
        >
          {p.title}
        </Chip>
      );
    })}
  </div>
);

const Shareholders = ({
  currentCompany,
  ledgerVersions,
  selectedVersion,
  setSelectedVersion,
}: Props) => {
  const i18n = useTranslation();
  const [searchValue, setSearchValue] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>("shares-desc");
  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});
  const [displayType, setDisplayType] = useState<"shares" | "votes">("shares");
  const [displayDiluted, setDisplayDiluted] = useState(false);
  const [selectedOptionsProgramIds, setSelectedOptionsProgramsIds] = useState<
    string[]
  >([]);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);

  const toggleRow = (id: string) => {
    setExpandedRows((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const downloadShareholders = async () => {
    setIsDownloadLoading(true);
    const response = await shareholdersDocumentDownload(
      currentCompany.orgNumber,
      selectedVersion!.formatedValue,
      displayDiluted ? selectedOptionsProgramIds : []
    );
    if (response.status === 200) {
      const blob = await response.blob();
      downloadBlob(
        blob,
        `${i18n.t("label.shareholders")}_${currentCompany.name}_${
          currentCompany.orgNumber
        }_${selectedVersion!.formatedValue}.pdf`
      );
    } else {
      console.error(response);
      monitoring.captureException(
        new Error("Error downloading shareholders document"),
        {
          extra: {
            status: response.status,
            text: response.statusText,
            company: currentCompany.orgNumber,
            version: selectedVersion?.formatedValue,
          },
        }
      );
    }
    setIsDownloadLoading(false);
  };

  const ledgerQuery = useLedgerQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );

  const shareHoldersQuery = useShareholdersQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );

  const shareBlocksQuery = useShareblocksQuery(
    currentCompany.orgNumber,
    selectedVersion?.formatedValue
  );
  const shareBlocks = shareBlocksQuery.data || [];

  const shareTypesQuery = useShareTypesQuery(currentCompany.orgNumber, "");

  const shareTypesByName = useMemo<Record<string, number>>(() => {
    if (!shareTypesQuery.isSuccess || !shareTypesQuery.data) {
      return {};
    }
    return shareTypesQuery.data.reduce(
      (result, shareType) => ({
        ...result,
        [shareType.name]: shareType.voteValue,
      }),
      {}
    );
  }, [shareTypesQuery.data, shareTypesQuery.isSuccess]);

  const entitiesQuery = useEntitiesQuery(currentCompany.orgNumber);
  const entitiesData = entitiesQuery.data || [];
  const entitiesMap: Record<string, Entity> = Object.fromEntries(
    entitiesData.map((e) => [e.id, e])
  );

  const optionsProgramsQuery = useOptionsProgramQuery(currentCompany.orgNumber);
  const optionsPrograms = optionsProgramsQuery.data || [];
  const selectedOptionPrograms = optionsPrograms.filter((x) =>
    selectedOptionsProgramIds.includes(x.id!)
  );

  const eventsQuery = useParentEventsQuery({
    orgNumber: currentCompany.orgNumber,
    offset: 0,
    limit: Number.MAX_SAFE_INTEGER,
  });
  const events = selectedVersion
    ? eventsQuery.data?.data.filter(
        (e) => !isGreaterLedgerVersion(e.date, selectedVersion.formatedValue)
      )
    : eventsQuery.data?.data || [];

  const {
    mappedShareHolders,
    hasSplit,
  }: {
    mappedShareHolders: ShareHolderWithDilution[] | undefined;
    hasSplit: boolean;
  } = useMemo(() => {
    if (
      !shareHoldersQuery.isSuccess ||
      !entitiesQuery.isSuccess ||
      !shareTypesQuery.isSuccess
    ) {
      return { mappedShareHolders: undefined, hasSplit: false };
    }

    const shareHolders = shareHoldersQuery.data
      .map((holder) => {
        const { nrOfShares, nrOfVotes, sharesByType } = getShareHolderMetrics(
          holder,
          shareTypesByName
        );

        if (Object.entries(entitiesMap).length === 0) {
          monitoring.captureException(new TypeError("Entities map is empty"), {
            contexts: { entity: holder },
          });
          return null;
        }

        const entity = getEntityWithFallback(entitiesMap, holder);

        if (!("type" in entity)) {
          console.error("No entity fallback found");
          monitoring.captureException(
            new TypeError("Shareholder missing in entities"),
            { contexts: { entitiesMap, holder } }
          );

          return null;
        }

        const shareHolder: ShareHolderWithMetricsAndEntity = {
          ...holder,
          nrOfShares,
          nrOfVotes,
          entity,
          sharesByType,
        };

        if (!shareHolderMatchSearchValue(shareHolder, searchValue)) {
          return null;
        }

        return shareHolder;
      })
      .filter((x) => x !== null);

    if (displayDiluted) {
      const { shareHoldersWithDilution, hasSplit: split } =
        getShareHoldersWithDilution(
          shareHolders,
          selectedOptionPrograms,
          shareTypesByName,
          entitiesMap,
          events || []
        );
      return {
        mappedShareHolders: sortHolders(shareHoldersWithDilution, sortBy),
        hasSplit: split,
      };
    }

    return {
      mappedShareHolders: sortHolders(shareHolders, sortBy),
      hasSplit: false,
    };
  }, [
    entitiesQuery.isSuccess,
    entitiesMap,
    sortBy,
    searchValue,
    shareHoldersQuery.isSuccess,
    shareHoldersQuery.data,
    shareTypesQuery.isSuccess,
    shareTypesByName,
    events,
    displayDiluted,
    selectedOptionPrograms,
  ]);

  const totalSharesByType = getTotalSharesByType(shareBlocks);

  const totalVotes = getTotalVotes(mappedShareHolders || [], shareTypesByName);
  const totalShares = ledgerQuery.data?.shares.total || 0;
  const dilutedTotals = getDilutionTotals(
    totalShares,
    mappedShareHolders || [],
    shareTypesByName,
    selectedOptionPrograms
  );

  const menuItems: Record<SortBy, string> = {
    "shares-desc": i18n.t("sortBy.shares.desc"),
    "shares-asc": i18n.t("sortBy.shares.asc"),
    "name-asc": i18n.t("sortBy.name.asc"),
    "name-desc": i18n.t("sortBy.name.desc"),
    "votes-desc": i18n.t("sortBy.votes.desc"),
    "votes-asc": i18n.t("sortBy.votes.asc"),
    "sharesDiluted-desc": i18n.t("sortBy.shares.desc"),
    "sharesDiluted-asc": i18n.t("sortBy.shares.asc"),
    "votesDiluted-desc": i18n.t("sortBy.votes.desc"),
    "votesDiluted-asc": i18n.t("sortBy.votes.asc"),
  };

  const getCurrentSortLabel = (): string => {
    // Assuming menuItems is already defined and populated as shown previously
    return menuItems[sortBy] || i18n.t("label.sortBy"); // Fallback to a default label if needed
  };

  const isLoading =
    shareHoldersQuery.isLoading ||
    entitiesQuery.isLoading ||
    shareTypesQuery.isLoading ||
    !Array.isArray(mappedShareHolders);
  const isSuccess =
    shareHoldersQuery.isSuccess &&
    entitiesQuery.isSuccess &&
    shareTypesQuery.isSuccess &&
    Array.isArray(mappedShareHolders);
  const errorCode =
    shareHoldersQuery.error?.errors[0]?.message.code ||
    entitiesQuery.error?.errors[0]?.message.code ||
    shareTypesQuery.error?.errors[0]?.message.code;

  const versionLabel = selectedVersion
    ? `${getFormattedDate(selectedVersion.date)} ${i18n.t("label.version")} ${
        selectedVersion.version
      }`
    : "";

  const isSortedHoldersEmpty = (mappedShareHolders || []).length === 0;

  const approvedLedgerVersions = ledgerVersions.filter(
    (version) => version.isApproved
  );

  const toggleDilutedView = () => {
    setSelectedOptionsProgramsIds(
      optionsPrograms
        .filter((x) => new Date(x.strikeEndDate) >= new Date())
        .map((x) => x.id!)
    );
    if (!displayDiluted) {
      // Set sort to diluted shares
      setSortBy("sharesDiluted-desc");
    }
    setDisplayDiluted(!displayDiluted);
  };

  return (
    <>
      <SharesPrint
        currentCompany={currentCompany}
        numberOfShareholders={shareHoldersQuery.data.length}
        ledgerData={ledgerQuery.data}
        currentVersion={selectedVersion}
        approvedLedgerVersions={approvedLedgerVersions}
      />
      <PageWrapper
        className="tw-py-4 max-sm:tw-pb-10"
        data-testid="shares-layout"
      >
        <div className="tw-relative">
          {errorCode && (
            <NoData
              type="error"
              title={i18n.t("error.fetch")}
              description={i18n.t(errorCode)}
            />
          )}
          {isLoading && <Loading />}
          {isSuccess && !isLoading && (
            <div>
              <div className="tw-flex tw-justify-between tw-py-4 max-sm:tw-hidden">
                <div className="tw-flex tw-flex-col tw-content-start tw-gap-2 print:tw-hidden md:tw-flex-row">
                  <SelectVersion
                    selectedVersion={selectedVersion}
                    availableVersions={ledgerVersions}
                    onChange={setSelectedVersion}
                  />
                  <div className="tw-ml-4 tw-flex tw-gap-2">
                    <Input
                      className="tw-w-52 max-md:tw-w-full"
                      placeholder={i18n.t("label.search")}
                      prefix={<SearchIcon />}
                      type="search"
                      value={searchValue}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                    />
                    <div className="tw-flex tw-gap-2">
                      <Menu className="max-sm:tw-w-full">
                        <Menu.Button
                          type="button"
                          className="tw-flex tw-items-center tw-rounded-md tw-p-5 max-md:tw-w-full"
                          isDropdown={false}
                          isLoading={isDownloadLoading}
                          onClick={() => downloadShareholders()}
                        >
                          <FilePdf className="tw-h-6 tw-w-6" />
                        </Menu.Button>
                      </Menu>

                      {/* excel */}
                      <Menu className="max-sm:tw-w-full">
                        <Menu.Button
                          className={clsxm(
                            "tw-flex tw-items-center tw-rounded-md tw-p-5 max-sm:tw-w-full",
                            {
                              "tw-cursor-not-allowed tw-opacity-50":
                                isSortedHoldersEmpty,
                            }
                          )}
                          disabled={isSortedHoldersEmpty}
                          isDropdown={false}
                          type="button"
                          onClick={() => {
                            if (isSortedHoldersEmpty) {
                              return;
                            }

                            const data = generateShareholderData(
                              mappedShareHolders,
                              displayDiluted,
                              {
                                votes: totalVotes,
                                shares: totalShares,
                                sharesByType: totalSharesByType,
                                dilutedShares: dilutedTotals.totalShares,
                                dilutedVotes: dilutedTotals.totalVotes,
                              },
                              i18n
                            );

                            makeAndDownloadExcel(data, {
                              currentCompany,
                              title: i18n.t("label.shareholders"),
                              version: versionLabel,
                              downloaded: i18n.t("label.downloaded", {
                                date: new Date().toLocaleDateString(
                                  getLocale()
                                ),
                              }),
                            });
                          }}
                        >
                          <ExcelIcon className="tw-h-6 tw-w-6" />
                        </Menu.Button>
                      </Menu>
                    </div>
                  </div>
                </div>
                {optionsPrograms.length > 0 && (
                  <Toggle
                    label={i18n.t("label.diluted")}
                    className="tw-ml-2 print:tw-hidden"
                    isActive={displayDiluted}
                    onClick={() => toggleDilutedView()}
                  />
                )}
              </div>
              {displayDiluted && (
                <OptionsProgramFilter
                  optionsPrograms={optionsPrograms}
                  selectedOptionsPrograms={selectedOptionsProgramIds}
                  setSelectedOptionsPrograms={setSelectedOptionsProgramsIds}
                  className="tw-py-2 print:tw-hidden max-sm:tw-hidden"
                />
              )}
              <div>
                <div
                  id="mobile-view"
                  className="tw-flex tw-flex-col tw-gap-2 tw-pb-10 sm:tw-hidden"
                >
                  <div className="tw-flex tw-flex-col tw-gap-4">
                    <Input
                      placeholder={i18n.t("label.search")}
                      prefix={<SearchIcon />}
                      type="search"
                      value={searchValue}
                      onChange={(event) => {
                        setSearchValue(event.target.value);
                      }}
                    />
                    {optionsPrograms.length > 0 && (
                      <Toggle
                        label={i18n.t("label.diluted")}
                        className="tw-ml-2 print:tw-hidden"
                        isActive={displayDiluted}
                        onClick={() => toggleDilutedView()}
                      />
                    )}
                  </div>
                  {displayDiluted && (
                    <OptionsProgramFilter
                      optionsPrograms={optionsPrograms}
                      selectedOptionsPrograms={selectedOptionsProgramIds}
                      setSelectedOptionsPrograms={setSelectedOptionsProgramsIds}
                      className="tw-py-2 print:tw-hidden"
                    />
                  )}
                  <div className="tw-flex tw-justify-between">
                    <Menu>
                      <Menu.Button
                        isDropdown={false}
                        className="tw-border-none tw-text-sm max-md:tw-w-full"
                      >
                        <div className="tw-flex tw-items-center tw-gap-1 tw-text-secondary">
                          {getCurrentSortLabel()}
                          <SortIcon size={24} />
                        </div>
                      </Menu.Button>
                      <Menu.Items align="bottomLeft">
                        {Object.entries(menuItems).map(([key, label]) => (
                          <Menu.Item
                            key={key}
                            as="button"
                            onClick={() => {
                              setSortBy(SortBySchema.parse(key));
                            }}
                          >
                            <SelectionMenuItem checked={sortBy === key}>
                              {label}
                            </SelectionMenuItem>
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Menu>
                    <Menu>
                      <Menu.Button className="tw-border-none tw-text-sm tw-text-secondary max-md:tw-w-full">
                        {displayType === "shares"
                          ? displayDiluted
                            ? i18n.t("label.diluted.shares")
                            : i18n.t("label.shares")
                          : displayDiluted
                          ? i18n.t("label.diluted.votes")
                          : i18n.t("label.votes")}
                      </Menu.Button>
                      <Menu.Items align="bottomRight">
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={clsxm(
                                "tw-block tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-sm",
                                active ? "tw-bg-gray-100" : "tw-text-gray-700"
                              )}
                              onClick={() => setDisplayType("shares")}
                            >
                              {displayDiluted
                                ? i18n.t("label.diluted.shares")
                                : i18n.t("label.shares")}
                            </button>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              type="button"
                              className={clsxm(
                                "tw-block tw-w-full tw-px-4 tw-py-2 tw-text-left tw-text-sm",
                                active ? "tw-bg-gray-100" : "tw-text-gray-700"
                              )}
                              onClick={() => setDisplayType("votes")}
                            >
                              {displayDiluted
                                ? i18n.t("label.diluted.votes")
                                : i18n.t("label.votes")}
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Menu>
                  </div>
                  {mappedShareHolders.map((holder) => {
                    if (!holder) {
                      return null;
                    }

                    const percentageValue =
                      displayType === "shares"
                        ? displayDiluted
                          ? formatPercentage(
                              holder.dilutedShares! / dilutedTotals.totalShares
                            )
                          : formatPercentage(holder.nrOfShares / totalShares)
                        : displayDiluted
                        ? formatPercentage(
                            holder.dilutedVotes! / dilutedTotals.totalVotes
                          )
                        : formatPercentage(holder.nrOfVotes / totalVotes);

                    const numberValue =
                      displayType === "shares"
                        ? displayDiluted
                          ? formatNumber(holder.dilutedShares!)
                          : formatNumber(holder.nrOfShares)
                        : displayDiluted
                        ? formatNumber(holder.dilutedVotes!)
                        : formatNumber(holder.nrOfVotes);

                    return holder ? (
                      <button
                        key={holder.id}
                        type="button"
                        className="tw-relative tw-rounded tw-p-4 tw-text-left"
                        style={{
                          boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.08)",
                        }}
                        onClick={() => toggleRow(holder.id)}
                      >
                        <div className="tw-flex tw-justify-between tw-gap-4">
                          <EntityItem
                            value={holder.entity}
                            showId
                            hasFlag
                            displayIcon={false}
                          />
                          <Description
                            align="right"
                            titleSize="base"
                            titleWeight="medium"
                            title={percentageValue}
                            description={numberValue}
                          />
                        </div>
                        <div
                          className={clsxm(
                            "tw-flex tw-flex-col tw-gap-4 tw-overflow-hidden tw-transition-all tw-duration-500 tw-ease-in-out",
                            {
                              "tw-max-h-96 tw-opacity-100":
                                expandedRows[holder.id],
                              "tw-max-h-0 tw-opacity-0":
                                !expandedRows[holder.id],
                            }
                          )}
                        >
                          <div className="tw-pt-2.5" id="drop">
                            <Description
                              title={
                                <p className="tw-text-sm tw-text-secondary">
                                  {displayType === "shares"
                                    ? i18n.t("label.shares")
                                    : i18n.t("label.votes")}
                                </p>
                              }
                              description={
                                <div className="tw-flex tw-items-end tw-gap-1">
                                  <p className="tw-text-base tw-font-medium tw-text-neutral-800">
                                    {displayType === "shares"
                                      ? formatPercentage(
                                          holder.nrOfShares / totalShares
                                        )
                                      : formatPercentage(
                                          holder.nrOfVotes / totalVotes
                                        )}
                                  </p>
                                  <p className="tw-h-[21px]">
                                    {displayType === "shares"
                                      ? formatNumber(holder.nrOfShares)
                                      : formatNumber(holder.nrOfVotes)}
                                  </p>
                                </div>
                              }
                            />
                          </div>
                          {displayDiluted && (
                            <div className="tw-pt-2.5" id="drop">
                              <Description
                                title={
                                  <p className="tw-text-sm tw-text-secondary">
                                    {displayType === "shares"
                                      ? i18n.t("label.diluted.shares")
                                      : i18n.t("label.diluted.votes")}
                                  </p>
                                }
                                description={
                                  <div className="tw-flex tw-items-end tw-gap-1">
                                    <p className="tw-text-base tw-font-medium tw-text-neutral-800">
                                      {displayType === "shares"
                                        ? formatPercentage(
                                            holder.dilutedShares! /
                                              dilutedTotals.totalShares
                                          )
                                        : formatPercentage(
                                            holder.dilutedVotes! /
                                              dilutedTotals.totalVotes
                                          )}
                                    </p>
                                    <p className="tw-h-[21px]">
                                      {displayType === "shares"
                                        ? formatNumber(holder.dilutedShares!)
                                        : formatNumber(holder.dilutedVotes!)}
                                    </p>
                                  </div>
                                }
                              />
                            </div>
                          )}
                          <div className="tw-flex tw-flex-col tw-gap-1">
                            {Object.entries(holder.sharesByType).map(
                              ([type, shares]) => {
                                return (
                                  <div
                                    className="tw-flex tw-justify-between tw-text-sm"
                                    key={type}
                                  >
                                    <p className="tw-font-medium">{type}</p>
                                    <p className="tw-font-medium">
                                      {formatNumber(shares)}
                                    </p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                        <div
                          className="tw-absolute tw-bottom-0 tw-left-0 tw-h-1 tw-rounded-bl"
                          style={{
                            backgroundColor: "#9E79ED",
                            width: `${
                              (holder.nrOfShares / totalShares) * 100
                            }%`,
                          }}
                        />
                      </button>
                    ) : null;
                  })}
                  <div className="tw-p-4">
                    <Description
                      align="right"
                      title={formatPercentage(1)}
                      titleSize="base"
                      titleWeight="medium"
                      description={formatNumber(
                        displayType === "shares"
                          ? displayDiluted
                            ? dilutedTotals.totalShares
                            : totalShares
                          : displayDiluted
                          ? dilutedTotals.totalVotes
                          : totalVotes
                      )}
                    />
                  </div>
                </div>
                {hasSplit && (
                  <p className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-border tw-p-6 tw-text-gray-500">
                    <InfoIcon className="tw-h-6 tw-w-6 tw-shrink-0" />
                    <div className="tw-text-bottom tw-text-sm">
                      {i18n.t("shareholders.splitCalculation")}
                    </div>
                  </p>
                )}
                <div className="max-sm:tw-hidden">
                  {mappedShareHolders.length > 0 ? (
                    <Table
                      fixed={false}
                      columns={
                        displayDiluted
                          ? [
                              {
                                name: "name",
                                title: i18n.t("label.shareholder"),
                              },
                              {
                                name: "shares",
                                title: i18n.t("label.shares"),
                              },
                              {
                                name: "sharesDiluted",
                                title: i18n.t("label.diluted.shares"),
                              },
                              {
                                name: "votes",
                                title: i18n.t("label.votes"),
                              },
                              {
                                name: "votesDiluted",
                                title: i18n.t("label.diluted.votes"),
                              },
                              {
                                name: "shareClass",
                                title: i18n.t("label.shareClass"),
                                sortable: false,
                                colspan: 1,
                                className: "tw-max-w-[200px]",
                              },
                            ]
                          : [
                              {
                                name: "name",
                                title: i18n.t("label.shareholder"),
                              },
                              {
                                name: "shares",
                                title: i18n.t("label.shares"),
                              },
                              {
                                name: "votes",
                                title: i18n.t("label.votes"),
                              },
                              {
                                name: "shareClass",
                                title: i18n.t("label.shareClass"),
                                sortable: false,
                                colspan: 2,
                                className: "tw-max-w-[200px]",
                              },
                            ]
                      }
                      sortBy={sortBy}
                      setSortBy={(value: string) => setSortBy(value as SortBy)}
                    >
                      {mappedShareHolders.map((holder) =>
                        holder ? (
                          <ShareHoldersRow
                            key={holder.id}
                            holder={holder}
                            totalShares={totalShares}
                            totalVotes={totalVotes}
                            dilutedTotals={dilutedTotals}
                          />
                        ) : null
                      )}
                      {!searchValue && (
                        <tr>
                          <td />
                          <td className="tw-px-6 tw-py-4">
                            <Description
                              title={`${formatPercentage(100 / 100)}`}
                              description={formatNumber(totalShares)}
                            />
                          </td>
                          {displayDiluted && (
                            <td className="tw-px-6 tw-py-4">
                              <Description
                                title={`${formatPercentage(100 / 100)}`}
                                description={formatNumber(
                                  dilutedTotals.totalShares
                                )}
                              />
                            </td>
                          )}
                          <td className="tw-px-6 tw-py-4">
                            <Description
                              title={`${formatPercentage(100 / 100)}`}
                              description={formatNumber(totalVotes)}
                            />
                          </td>
                          {displayDiluted && (
                            <td className="tw-px-6 tw-py-4">
                              <Description
                                title={`${formatPercentage(100 / 100)}`}
                                description={formatNumber(
                                  dilutedTotals.totalVotes
                                )}
                              />
                            </td>
                          )}
                          <td colSpan={displayDiluted ? 1 : 2} />
                        </tr>
                      )}
                    </Table>
                  ) : (
                    <div className="tw-rounded tw-border">
                      <NoData />
                    </div>
                  )}
                </div>
              </div>

              <div className="tw-hidden tw-grid-cols-9 tw-gap-4 tw-px-4 tw-pt-2 print:tw-mt-8 print:tw-grid md:tw-grid-cols-2 lg:tw-grid-cols-3">
                <div className="tw-col-span-3 tw-font-medium md:tw-col-span-2 lg:tw-col-span-1">
                  {i18n.t("shares.totalAllHolders")}
                </div>
                <div className="tw-col-span-2">
                  <Description
                    title={i18n.t("label.shares.count", {
                      count: totalShares,
                    })}
                    description={`${formatPercentage(100 / 100)}`}
                  />
                </div>
                <div className="tw-col-span-2">
                  <Description
                    title={i18n.t("label.votes.count", {
                      count: totalVotes,
                    })}
                    description={`${formatPercentage(100 / 100)}`}
                  />
                </div>
              </div>
            </div>
          )}
          <BottomBar
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            ledgerVersions={ledgerVersions}
            enableExcelDownload={!isSortedHoldersEmpty}
            isDownloadLoading={isDownloadLoading}
            downloadPdf={downloadShareholders}
            downloadExcel={() => {
              const data = generateShareholderData(
                mappedShareHolders ?? [],
                displayDiluted,
                {
                  votes: totalVotes ?? 0,
                  shares: totalShares ?? 0,
                  sharesByType: totalSharesByType ?? {},
                  dilutedShares: dilutedTotals.totalShares,
                  dilutedVotes: dilutedTotals.totalVotes,
                },
                i18n
              );

              makeAndDownloadExcel(data, {
                currentCompany,
                title: i18n.t("label.shareholders"),
                version: versionLabel,
                downloaded: i18n.t("label.downloaded", {
                  date: new Date().toLocaleDateString(getLocale()),
                }),
              });
            }}
          />
        </div>
      </PageWrapper>
    </>
  );
};

export { sortHolders };
export type { SortBy };
export default Shareholders;
