import { UseMutationResult } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IRequestError } from "../../api";
import bankId from "../../api/rest/auth/BankID";
import { Loading } from "../../components/design-system/Loading";
import { BankIdAuthDetails, LoggedInUser } from "../../types/models/auth";
import BankIdControls from "./BankIdControls";

const BankIdMobileLogin = (props: {
  initMutation: UseMutationResult<
    BankIdAuthDetails,
    IRequestError,
    string,
    unknown
  >;
  onSuccess: ({ user }: { user: LoggedInUser }) => void;
  onRetry: () => void;
  onCancel: () => void;
  onClickSameDevice: () => void;
}) => {
  const i18n = useTranslation();
  const [enableQueries, setEnableQueries] = useState(false);

  // Mutation to init -> inProgress
  useEffect(() => {
    if (props.initMutation.isSuccess) {
      setEnableQueries(true);
    }
  }, [props.initMutation.isSuccess]);

  // Two queries running whilst in progress
  const qrCodeQuery = bankId.useQRCodeQuery(
    props.initMutation.data?.qrStartToken || "",
    {
      refetchInterval: 3000,
      enabled: enableQueries,
    }
  );
  const collectQuery = bankId.useCollectAuthQuery(
    props.initMutation.data?.orderRef || "",
    {
      onSuccess: (data) => {
        const { status: authStatus } = data;
        if (authStatus === "complete") {
          setEnableQueries(false);
          props.onSuccess(data);
        }
        if (authStatus === "failed") {
          throw new Error("Failed to collect");
        }
      },
      retry: false,
      refetchInterval: 2000,
      enabled: enableQueries,
    }
  );

  const cancel = () => {
    setEnableQueries(false);
    props.onCancel();
  };

  const mutations = [props.initMutation, qrCodeQuery, collectQuery];
  const failedMutation = mutations.find((x) => x.isError);
  const isSuccess = collectQuery.data?.status === "complete";
  const status: "init" | "success" | "inProgress" | "error" = failedMutation
    ? "error"
    : isSuccess
    ? "success"
    : props.initMutation.isSuccess
    ? "inProgress"
    : "init";
  const error =
    failedMutation &&
    (failedMutation.error.errors
      ? i18n.t(failedMutation.error.errors[0].message.code)
      : i18n.t("error.general"));

  const hintCode =
    collectQuery.data &&
    "hintCode" in collectQuery.data &&
    collectQuery.data.hintCode;

  const sameDevice = () => {
    setEnableQueries(false);
    props.onClickSameDevice();
  };

  if (status === "init" || qrCodeQuery.isLoading) {
    return <Loading />;
  }

  return (
    <BankIdControls
      title={i18n.t("login.bankId")}
      status={status}
      qrCode={
        hintCode && hintCode === "userSign"
          ? undefined
          : qrCodeQuery.data?.qrCode
      }
      cancel={cancel}
      sameDevice={sameDevice}
      error={error}
      retry={props.onRetry}
    />
  );
};

export default BankIdMobileLogin;
