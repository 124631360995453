import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import type { MockTruidAuthRequest } from "../../api/rest/auth/authTest";
import BankIdLogin from "../../auth/BankId/BankIdLogin";
import MockedBankIdLogin from "../../auth/BankId/MockedBankIdLogin";
import { Button } from "../../components/design-system/Button";
import { FormGroup, FormLabel } from "../../components/design-system/FormGroup";
import { BankIdIcon, TruidIcon } from "../../components/design-system/icons";
import { Input } from "../../components/design-system/Input";
import { Loading } from "../../components/design-system/Loading";
import { SelectCountry } from "../../components/SelectCountry";
import { featureToggles } from "../../config";
import { useSession } from "../../context/session";
import { useToggles } from "../../hooks/useToggles";
import isMobileDevice from "../../utils/isMobileDevice";
import logo from "./logo.png";

const SignInForm = () => {
  const [step, setStep] = useState<
    "bankId" | "Truid" | "initial" | "bankIdMock" | "TruidMock" | "TruidCollect"
  >("initial");
  const i18n = useTranslation();
  const { isFeatureEnabled } = useToggles();

  const mockAuthEnabled = isFeatureEnabled(featureToggles.ENABLE_MOCK_AUTH);

  const {
    onInitTruidSignIn,
    onCollectTruidAuth,
    collectTruidAuthMutation,
    initTruidAuthMutation,
    onInitMockTruidSignIn,
    initMockTruidAuthMutation,
    TruidLoginState,
    handleSignedIn,
  } = useSession();

  const [TruidMockState, setTruidLoginState] = useState<
    Partial<MockTruidAuthRequest>
  >({
    countryCode: "SE",
  });

  const handleBankIdClick = () => {
    setStep("bankId");
  };

  const handleTruidClick = () => {
    setStep("Truid");
    onInitTruidSignIn();
  };

  const handleMockClick = () => {
    setStep("bankIdMock");
  };

  const handleStartMockTruidClick = () => setStep("TruidMock");

  const handleMockTruid = () => {
    onInitMockTruidSignIn(TruidMockState as MockTruidAuthRequest);
  };

  const isTruidRequestLoading = initTruidAuthMutation?.isLoading;

  const isTruidRequestError =
    initTruidAuthMutation?.isError || collectTruidAuthMutation?.isError;

  const truiIdError = initTruidAuthMutation?.isError
    ? initTruidAuthMutation.error
    : collectTruidAuthMutation?.isError
    ? collectTruidAuthMutation.error
    : null;

  useEffect(() => {
    const TruidCallback = (event: MessageEvent) => {
      const payload = event.data;
      if (!payload || event.origin !== window.location.origin) {
        return;
      }

      if (payload.type === "TruidResponse") {
        onCollectTruidAuth(payload.code ?? payload.error, payload.state);
        setStep("TruidCollect");
      }
    };

    window.addEventListener("message", TruidCallback);
    return () => {
      window.removeEventListener("message", TruidCallback);
    };
  }, [onCollectTruidAuth]);

  useEffect(() => {
    const url = TruidLoginState?.authorizationUrl;
    const isMobile = isMobileDevice();
    if (isMobile && url && url.length > 0) {
      window.location.href = url;
    }
  }, [TruidLoginState?.authorizationUrl]);

  return (
    <div className="tw-w-full">
      {step === "initial" && (
        <div className="tw-flex tw-flex-col tw-gap-8 tw-text-center">
          <img src={logo} className="tw-mx-auto tw-h-24 tw-w-24" alt="logo" />
          <div>
            <h1 className="tw-font-medium tw-text-primary">
              {i18n.t("login.title")}
            </h1>
            <p className="tw-text-secondary">{i18n.t("login.tip")}</p>
          </div>
          <div className="tw-flex tw-flex-col tw-justify-center tw-gap-2 lg:tw-flex-row">
            <Button
              onClick={handleBankIdClick}
              variant="solid"
              color="primary"
              prefix={<BankIdIcon />}
            >
              {i18n.t("login.withBankId")}
            </Button>
            <Button
              variant="outline"
              color="secondary"
              onClick={handleTruidClick}
              prefix={
                <span>
                  <TruidIcon />
                </span>
              }
            >
              {i18n.t("login.Truid")}
            </Button>
            {mockAuthEnabled && (
              <Button
                color="danger"
                prefix={<BankIdIcon />}
                variant="outline"
                onClick={handleMockClick}
              >
                Mock
              </Button>
            )}
            {mockAuthEnabled && (
              <Button
                color="danger"
                prefix={<TruidIcon />}
                variant="outline"
                onClick={handleStartMockTruidClick}
              >
                Mock Truid
              </Button>
            )}
          </div>
        </div>
      )}
      {step === "TruidMock" && (
        <div className=" tw-w-96 tw-px-4">
          <form
            className="tw-flex tw-w-full tw-flex-col tw-gap-4"
            onSubmit={(event) => {
              handleMockTruid();
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <FormGroup>
              <FormLabel htmlFor="passportNumber">Passport Number</FormLabel>
              <Input
                value={TruidMockState.passportNumber}
                id="passportNumber"
                type="text"
                onChange={(e) =>
                  setTruidLoginState({
                    ...TruidMockState,
                    passportNumber: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="TruidUserId">Truid UserId</FormLabel>
              <Input
                id="TruidUserId"
                value={TruidMockState.TruidUserId}
                type="text"
                onChange={(e) =>
                  setTruidLoginState({
                    ...TruidMockState,
                    TruidUserId: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="countryCode">Nationality</FormLabel>
              <SelectCountry
                name="countryCode"
                onChange={(newValue) =>
                  setTruidLoginState({
                    ...TruidMockState,
                    countryCode: newValue,
                  })
                }
                value={TruidMockState.countryCode as string}
              />
            </FormGroup>
            {initMockTruidAuthMutation?.isError && (
              <div className="tw-flex tw-justify-between">
                <pre>
                  {JSON.stringify(initMockTruidAuthMutation?.error, null, 2)}
                </pre>
              </div>
            )}
            <div className="tw-flex tw-justify-between">
              <Button
                type="button"
                onClick={() => setStep("initial")}
                disabled={initMockTruidAuthMutation?.isLoading}
              >
                Back
              </Button>
              <Button
                type="submit"
                disabled={initMockTruidAuthMutation?.isLoading}
              >
                {initMockTruidAuthMutation?.isError
                  ? "Try again"
                  : "Mock Truid"}
              </Button>
            </div>
          </form>
        </div>
      )}
      {step === "bankId" && (
        <BankIdLogin
          onSuccess={(data) => handleSignedIn(data, "BankId")}
          onCancel={() => setStep("initial")}
        />
      )}
      {step === "Truid" && isTruidRequestLoading && <Loading />}
      {step === "Truid" && TruidLoginState && (
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-space-y-8">
          <iframe
            referrerPolicy="no-referrer"
            title="Truid"
            src={TruidLoginState.authorizationUrl}
            height={500}
            width={400}
          />
          <Button
            onClick={() => {
              initTruidAuthMutation?.reset();
              collectTruidAuthMutation?.reset();
              setStep("initial");
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.cancel")}
          </Button>
        </div>
      )}
      {step === "TruidCollect" && collectTruidAuthMutation?.isLoading && (
        <Loading />
      )}
      {isTruidRequestError && (
        <div className="tw-flex tw-flex-col">
          {truiIdError?.status === 403
            ? i18n.t("auth.Truid.loginFailed.useBankId")
            : i18n.t("auth.Truid.loginFailed")}

          <Button
            onClick={() => {
              initTruidAuthMutation?.reset();
              collectTruidAuthMutation?.reset();
              setStep("initial");
            }}
            variant="clean"
            color="primary"
          >
            {i18n.t("label.return")}
          </Button>
        </div>
      )}
      {step === "bankIdMock" && (
        <MockedBankIdLogin
          onSuccess={(data) => handleSignedIn(data, "BankId")}
          onCancel={() => setStep("initial")}
        />
      )}
    </div>
  );
};

export default SignInForm;
