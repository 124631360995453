import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import bankId from "../../api/rest/auth/BankID";
import { notify } from "../../components/design-system/Notifications";
import isMobileDevice from "../../utils/isMobileDevice";
import { SignMethodProps } from "..";
import BankIdDeviceSign from "./BankIdDeviceSign";
import BankIdMobileSign from "./BankIdMobileSign";

const BankIdSign = (props: SignMethodProps) => {
  const i18n = useTranslation();
  const mobile = isMobileDevice();
  const [device, setDevice] = useState(false);

  const initMutation = bankId.useInitSignMutation({
    onError: () => {
      notify(i18n.t("error.general"), { type: "error" });
    },
  });

  const onInit = () => {
    initMutation.mutate(props.request);
  };

  // Init
  useEffect(() => onInit(), []);

  // If on a mobile device or the "Same device" option is clicked
  if (mobile || device) {
    return (
      <BankIdDeviceSign
        initMutation={initMutation}
        onRetry={onInit}
        onSuccess={props.onSuccess}
        onCancel={props.onCancel}
      />
    );
  }

  return (
    <BankIdMobileSign
      initMutation={initMutation}
      onRetry={onInit}
      onSuccess={props.onSuccess}
      onCancel={props.onCancel}
      onClickSameDevice={() => setDevice(true)}
    />
  );
};

export default BankIdSign;
